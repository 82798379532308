.login-form {
    max-width: 600px;

}
.login-container{
    background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
}
.login-form-forgot {
    float: right;
}
 .login-form-forgot {
    float: left;
}
.login-form-button {
    width: 100%;
}